import React, { useEffect } from 'react';
import Layout from "../components/layout"
import Seo from "../components/seo"


const IndexPage = ({data}) => {
  useEffect(() => {
    window.location.href = "https://anthony-k-do.admin.datocms.com";
  })
  return (
  <Layout>   
    <Seo title="Admin" />
  </Layout>
  )
}

export default IndexPage